import { Id } from '@restworld/data-services/src/lib/utils/common_types';
import { EntityId } from '@restworld/utility-types';
import { ENDPOINTS } from 'constants/api';
import useAuth from 'hooks/useAuth';
import { useMemo } from 'react';
import { Service } from 'service/baseService';
import dynamicAxios from 'utils/axios';

class ServiceAccounts extends Service {
  me() {
    return dynamicAxios(this.jwt).get(ENDPOINTS.accounts.me);
  }

  fetchUserType({ email }: { email?: string }) {
    return dynamicAxios(this.jwt).get<{ type: string }>(`${ENDPOINTS.accounts.root}/user_type`, {
      params: { email }
    });
  }

  migrateUser({ email, toType }: { email?: string; toType?: string }) {
    return dynamicAxios(this.jwt).post<string>(`${ENDPOINTS.accounts.root}/migrate_user`, {
      email,
      to_type: toType
    });
  }

  login(email: string, password: string) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.accounts.login, { email, password });
  }

  createSetupCheckoutSession({
    employerId,
    companyId
  }: {
    employerId?: string;
    companyId?: string;
  }) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.accounts.setup_checkout_session, {
      params: { employer_id: employerId, company_id: companyId }
    });
  }

  getIntercomContactId({ email }: { email?: string }) {
    return dynamicAxios(this.jwt).get<Id>(ENDPOINTS.accounts.get_intercom_contact_id, {
      params: { email }
    });
  }

  createEmployerUserContact(data: any) {
    return dynamicAxios(this.jwt).post<Id>(ENDPOINTS.accounts.employerUserContact, data);
  }

  updateEmployerUserContact(data: any) {
    return dynamicAxios(this.jwt).patch<Id>(ENDPOINTS.accounts.employerUserContact, data);
  }

  getEmployerUserContactAccessLink(eucId?: string) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.accounts.employerUserContactAccessLink, {
      params: { user_id: eucId }
    });
  }

  deleteSecondaryContact({ id }: { id?: number }) {
    return dynamicAxios(this.jwt).delete<string>(`${ENDPOINTS.accounts.secondaryContacts}/${id}`);
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceAccounts(jwt), [jwt]);
};
export default useService;

export const createAccountService = (jwt?: string) => new ServiceAccounts(jwt);

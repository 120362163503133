import {
  JobApplicationSource,
  EntityId,
  // WorkerShortlist,
  JobApplication,
  ConversationStep
} from '@restworld/utility-types';
// import { ENDPOINTS } from 'constants/api';
import { ENDPOINTS } from '@restworld/data-services';
import useAuth from 'hooks/useAuth';
import { useMemo } from 'react';
import { Service } from 'service/baseService';
import dynamicAxios from 'utils/axios';
import { CreateJobApplication, Id } from '@restworld/data-services/src/lib/utils/common_types';

export type IndexSpontaneousApplicationsResponse = {
  application: JobApplication;
  worker_distance: number;
}[];

class ServiceWorkerJobPositions extends Service {
  fetchWorkerTimeline(workerId: EntityId, jobPositionId?: EntityId) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.workerJobPositions.worker_status, {
      params: { worker_id: workerId, job_position_id: jobPositionId }
    });
  }

  fetchShortlists(
    limit: number,
    offset: number,
    jobPositionId?: EntityId,
    statusKey?: string,
    useCache?: boolean
  ) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.workerJobPositions.shortlists, {
      params: { limit, offset, job_position_id: jobPositionId, status_key: statusKey },
      headers: {
        useCache: !!useCache
      }
    });
  }

  sendShortlist(shortlistId: EntityId) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.workerJobPositions.send_shortlist, {
      shortlist_id: shortlistId
    });
  }

  cleanPendingApplications(jobPositionId: EntityId) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.workerJobPositions.clean_pending_applications, {
      params: {
        job_position_id: jobPositionId
      }
    });
  }

  createJobApplication({
    jobPositionId,
    workerId,
    statusKey,
    source,
    params
  }: {
    jobPositionId: string;
    workerId: string | Array<string>;
    statusKey: string;
    source?: JobApplicationSource;
    params?: Partial<JobApplication>;
  }) {
    return dynamicAxios(this.jwt).post<CreateJobApplication>(
      ENDPOINTS.workerJobPositions.jobApplication,
      {
        job_position_id: jobPositionId,
        worker_id: workerId,
        status_key: statusKey,
        source,
        ...params
      }
    );
  }

  updateJobApplication({
    jobPositionId,
    workerId,
    params,
    restaurantId
  }: {
    workerId: EntityId | Array<string>;
    params: Partial<JobApplication>;
    restaurantId?: string;
    jobPositionId?: EntityId;
  }) {
    return dynamicAxios(this.jwt).patch(ENDPOINTS.workerJobPositions.jobApplication, {
      job_position_id: jobPositionId,
      restaurant_id: restaurantId,
      worker_id: workerId,
      ...params
    });
  }

  addWorkerToShortlist(shortlistId: EntityId, workerId: EntityId, comment: string) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.workerJobPositions.add_worker_to_shortlist, {
      shortlist_id: shortlistId,
      worker_id: workerId,
      notes: comment
    });
  }

  deleteWorkerFromShortlist(shortlistId: EntityId, workerId: EntityId) {
    return dynamicAxios(this.jwt).delete(
      ENDPOINTS.workerJobPositions.remove_worker_from_shortlist,
      {
        data: {
          shortlist_id: shortlistId,
          worker_id: workerId
        }
      }
    );
  }

  updateWorkerShortlist(shortlistId: EntityId, workerId: EntityId, comment: string) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.workerJobPositions.update_worker_shortlist, {
      shortlist_id: shortlistId,
      worker_id: workerId,
      notes: comment
    });
  }

  createShortlist(jobPositionId: EntityId, workerId?: EntityId, comment?: string) {
    let workers: any = [];
    if (workerId) {
      workers = [{ worker_id: workerId, notes: comment }];
    }
    return dynamicAxios(this.jwt).post(ENDPOINTS.workerJobPositions.shortlist, {
      job_position_id: jobPositionId,
      workers
    });
  }

  deleteShortlist(shortlistId: EntityId) {
    return dynamicAxios(this.jwt).delete(ENDPOINTS.workerJobPositions.shortlist, {
      data: { id: shortlistId }
    });
  }

  listSpontaneousApplications({
    worker_id,
    restaurant_id
  }: {
    worker_id?: string;
    restaurant_id?: string;
  }) {
    return dynamicAxios(this.jwt).get<IndexSpontaneousApplicationsResponse>(
      ENDPOINTS.workerJobPositions.listSpontaneousApplications,
      {
        params: { worker_id, restaurant_id }
      }
    );
  }

  fetchEmployerJobApplications({
    limit,
    offset,
    employerId,
    search_vector,
    spontaneous_applications
  }: {
    limit: number;
    offset: number;
    employerId: string;
    search_vector?: string;
    spontaneous_applications?: boolean;
  }) {
    return dynamicAxios(this.jwt).post<EmployerJobApplications>(
      ENDPOINTS.workerJobPositions.graphql,
      {
        query: indexEmployerJobApplicationsQuery,
        variables: {
          limit,
          offset,
          employer_id: employerId,
          search_vector,
          spontaneous_applications
        }
      }
    );
  }

  // TODO implement these in data-services
  fetchConversationSteps({
    limit,
    offset,
    jobApplicationId
  }: {
    jobApplicationId: string;
    limit: number;
    offset: number;
  }) {
    return dynamicAxios(this.jwt).get<IndexConversationStepsResponse>(
      `${ENDPOINTS.workerJobPositions.jobApplications}/conversations/steps`,
      {
        params: { job_application_id: jobApplicationId, limit, offset }
      }
    );
  }

  createConversationStep(params: Partial<ConversationStep>) {
    return dynamicAxios(this.jwt).post<Id>(
      `${ENDPOINTS.workerJobPositions.jobApplications}/conversations/steps`,
      {
        ...params
      }
    );
  }

  updateConversationStep(params: Partial<ConversationStep>) {
    return dynamicAxios(this.jwt).put(
      `${ENDPOINTS.workerJobPositions.jobApplications}/conversations/steps/${params.id}`,
      {
        ...params
      }
    );
  }

  deleteConversationStep(id?: string) {
    return dynamicAxios(this.jwt).delete(
      `${ENDPOINTS.workerJobPositions.jobApplications}/conversations/steps/${id}`
    );
  }

  getInterestedWorkersCsv(jobPositionId: string) {
    return dynamicAxios(this.jwt).get(
      `${ENDPOINTS.workerJobPositions.jobApplications}/interested_workers_csv`,
      {
        params: { job_position_id: jobPositionId },
        responseType: 'blob'
      }
    );
  }
}

export type IndexConversationStepsResponse = {
  hits: number;
  data: ConversationStep[];
};

export type EmployerJobApplications = {
  hits: number;
  data: {
    // distance in meters
    worker_distance: number;
    job_application: JobApplication;
    shortlist_inserted_at: string;
    shortlist_id: string;
    worker_shortlist_note: string;
  }[];
};

export const indexEmployerJobApplicationsQuery = `
  query index_employer_job_applications (
    $limit: Int!,
    $offset: Int!,
    $employer_id: String!,
    $search_vector: String,
    $spontaneous_applications: Boolean
  ) {
    index_employer_job_applications (
      limit: $limit,
      offset: $offset,
      employer_id: $employer_id,
      search_vector: $search_vector
      spontaneous_applications: $spontaneous_applications
    ) {
      hits
      data {
        worker_distance
        shortlist_inserted_at
        job_application {
          id
          worker {
            user_id
            name
            surname
            birthdate
            cv
            user {
              email
            }
            phone_numbers {
              phone_number
              primary
            }
            transportation_options {
              transportation_var {
                key
                label
              }
            }
            main_picture
            worker_shortlist_notes {
              shortlist_id
              shortlist {
                job_position_id
              }
              worker_id
              notes
              inserted_at
              updated_at
            }
          }
          job_position_id
          job_position {
            id
            occupation {
              id
              label_it {
                label
              }
            }
            restaurant {
              id
              restaurant_name
              address
            }
          }
          restaurant_id
          restaurant {
            id
            restaurant_name
            address
          }
          status_key
          status {
            key
            label
            sentiment
          }
        }
        worker_shortlist_note
        shortlist_id
      }
    }
  }
`;

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceWorkerJobPositions(jwt), [jwt]);
};
export default useService;

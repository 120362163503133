import { Card, Stack, CardContent, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import CropLongText from 'components/CropLongText';
import { EmployerUserContact } from 'service/organizations';

import { useOrganizationService } from 'service';

const OrganizationDetails = () => {
  const [searchParams] = useSearchParams();
  const organizationService = useOrganizationService();
  const organizationId = searchParams.get('organizationId');
  const { data: organization, isFetching } = useQuery({
    queryKey: [`organization_${organizationId}`],
    queryFn: async () => {
      return (await organizationService.showOrganization(organizationId as string)).data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: typeof organizationId === 'string'
  });

  return (
    <>
      {isFetching ? (
        <Card variant="outlined" sx={{ minWidth: '250px' }}>
          <CardContent>
            <Skeleton variant="text" width="100%" height={30} />
            <Skeleton variant="text" width="100%" height={30} />
            <Skeleton variant="text" width="100%" height={30} />
            <Skeleton variant="text" width="100%" height={30} />
          </CardContent>
        </Card>
      ) : (
        organization && (
          <Card variant="outlined">
            <CardContent>
              <Stack spacing={2}>
                <CropLongText
                  label={organization?.name}
                  variant="body1"
                  fontWeight={900}
                  visibleLength={40}
                />

                <Stack>
                  {organization?.employer_user_contacts?.map((euc: EmployerUserContact) => {
                    return (
                      <CropLongText
                        key={euc?.user_id}
                        label={
                          euc?.user?.name
                            ? `${euc?.user?.name} - ${euc?.user?.email}`
                            : `${euc?.user?.email}`
                        }
                        variant="subtitle2"
                        visibleLength={40}
                      />
                    );
                  })}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )
      )}
    </>
  );
};
export default OrganizationDetails;

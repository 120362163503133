import { UnanalysedParsedCv } from '@restworld/data-services';
import { FormValues } from '.';
import { WorkerPastExperience } from '@restworld/utility-types';
import { format } from 'date-fns';

function compareStrings(input?: string | null, edited?: string | null) {
  if ((!input && !edited) || (!input && edited === '') || (input === '' && !edited))
    return undefined;
  if (input !== edited) return edited;
  return undefined;
}

export function processPhoneNumbers(parsedCvInfo: UnanalysedParsedCv['parsed_info']) {
  const phoneNumberObj = parsedCvInfo?.phone_numbers?.[0] ?? null;
  let phoneNumber = '';
  if (!!phoneNumberObj)
    phoneNumber = `+${phoneNumberObj.country_dialling}${phoneNumberObj.phone_number}`;

  return phoneNumber;
}

function compareDates(input?: string | Date | null, edited?: string | Date | null) {
  if (!input || !edited) return edited;
  if (!!input && !!edited && processDate(input) !== processDate(edited)) return processDate(edited);
  return undefined;
}

function processDate(date?: string | Date | null) {
  if (!!date) {
    return format(new Date(date), 'MM/dd/yyyy');
  }
  return date;
}

function processPastExperiences(editedData: FormValues) {
  const pastExperiences = editedData.past_experiences?.map((experience) => {
    return {
      restaurant_name: experience.restaurant_name,
      city: experience.city,
      description: experience.description,
      from_date: processDate(experience.from_date),
      to_date: processDate(experience.to_date),
      occupation_id: (experience as unknown as WorkerPastExperience).occupation?.id,
      domain_interest: (experience as unknown as WorkerPastExperience).domain_interest_var?.id
    };
  });
  return pastExperiences;
}

export function getPayloadDiff(
  inputData: UnanalysedParsedCv['parsed_info'],
  editedData: FormValues
) {
  return {
    name: compareStrings(inputData.name, editedData.name),
    surname: compareStrings(inputData.surname, editedData.surname),
    email: compareStrings(inputData.email, editedData.email),
    phone_number: compareStrings(processPhoneNumbers(inputData), editedData.phone_number),
    birthdate: compareDates(inputData.birthdate, editedData.birthdate),
    street_address: compareStrings(inputData.location?.street_address, editedData.street_address),
    city: compareStrings(inputData.location?.city, editedData.city),
    postal_code: compareStrings(inputData.location?.postal_code, editedData.postal_code),
    region: compareStrings(inputData.location?.region, editedData.region),
    country: compareStrings(inputData.location?.country, editedData.country),
    presentation: compareStrings(inputData.presentation, editedData.presentation),
    past_experiences: processPastExperiences(editedData)
  };
}

export const emptyReset = {
  name: '',
  surname: '',
  email: '',
  phone_number: '',
  birthdate: undefined,
  presentation: '',
  city: '',
  country: '',
  country_code: '',
  county: '',
  region: '',
  street_address: '',
  postal_code: ''
};

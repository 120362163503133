import { EntityId } from '@restworld/utility-types';
import { ENDPOINTS } from 'constants/api';
import { Service } from 'service/baseService';
import dynamicAxios from 'utils/axios';
import { useMemo } from 'react';
import useAuth from 'hooks/useAuth';

import {
  EmployerJobApplications,
  IndexWorkers,
  WorkerFilterType,
  WorkerListItem
} from '@restworld/data-services';
// import { WorkerFilterTypeUpdated } from 'pages/showJobPosition/workerListing';

class ServiceWorker extends Service {
  // fetchWorkers({
  //   jobPositionId,
  //   limit,
  //   offset,
  //   order_by,
  //   filters,
  //   useCache
  // }: {
  //   jobPositionId?: string;
  //   limit: number;
  //   offset: number;
  //   order_by?: Array<string>;
  //   filters?: WorkerFilterType;
  //   useCache?: boolean;
  //   backgroundCall?: boolean;
  // }) {
  //   const appliedFilters = filters || {};
  //   return dynamicAxios(this.jwt).get(ENDPOINTS.workers.root, {
  //     params: {
  //       limit,
  //       offset,
  //       job_position_id: jobPositionId,
  //       ...appliedFilters,
  //       order_by
  //     },
  //     headers: {
  //       useCache: !!useCache
  //     }
  //   });
  // }

  fetchWorkers({
    jobPositionId,
    limit,
    offset,
    order_by,
    filters,
    useCache
  }: {
    jobPositionId?: string;
    limit: number;
    offset: number;
    order_by?: Array<string>;
    filters?: WorkerFilterType;
    useCache?: boolean;
  }) {
    const appliedFilters = filters || {};
    return dynamicAxios(this.jwt).post<IndexWorkers>(ENDPOINTS.workers.graphql, {
      query: indexWorkersQuery,
      variables: { limit, offset, job_position_id: jobPositionId, ...appliedFilters, order_by }
    });
  }

  updateResearchStatus(workerId: EntityId, statusKey: string) {
    return dynamicAxios(this.jwt).patch(ENDPOINTS.workers.update_research_status, {
      worker_id: workerId,
      status_key: statusKey
    });
  }

  fetchTimeline({ workerId, jobPositionId }: { workerId: EntityId; jobPositionId: EntityId }) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.worker_job_positions.status, {
      params: {
        worker_id: workerId,
        job_position_id: jobPositionId
      }
    });
  }

  fetchActiveApplications({ workerId }: { workerId: EntityId }) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.workers.active_applications, {
      params: {
        id: workerId
      }
    });
  }

  fetchWorker(workerId: EntityId) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.workers.root}/${workerId}`);
  }

  updateWorker(workerId: EntityId, params: any) {
    return dynamicAxios(this.jwt).patch(`${ENDPOINTS.workers.root}/${workerId}`, params);
  }

  uploadCV(workerId: EntityId, cv: File) {
    const formData = new FormData();
    formData.append('user_id', workerId);
    formData.append('cv', cv);
    return dynamicAxios(this.jwt).post(ENDPOINTS.workers.upload_cv, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  deleteCV(workerId: EntityId) {
    return dynamicAxios(this.jwt).delete(ENDPOINTS.workers.delete_cv, {
      data: { user_id: workerId }
    });
  }

  sendEmailToWorker(email: string, subject: string, content: string) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.workers.send_email, {
      email,
      subject,
      content
    });
  }

  sendAccessEmail(workerId: EntityId) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.workers.send_access_email, {
      worker_id: workerId
    });
  }

  deleteWorker(workerId: EntityId) {
    return dynamicAxios(this.jwt).delete(ENDPOINTS.workers.root, {
      params: { id: workerId }
    });
  }

  fetchSimilarWorkers({
    workerId,
    jobPositionId,
    limit,
    limitWorkerDistance,
    workerDistanceRadius
  }: {
    workerId: EntityId;
    jobPositionId?: string;
    limit?: number;
    limitWorkerDistance?: boolean;
    workerDistanceRadius?: number;
  }) {
    return dynamicAxios(this.jwt).get<WorkerListItem[]>(
      `${ENDPOINTS.workers.similarWorkers}/${workerId}`,
      {
        params: {
          limit,
          job_position_id: jobPositionId,
          limit_worker_distance: limitWorkerDistance,
          worker_distance_radius: workerDistanceRadius
        }
      }
    );
  }

  fetchSimilarWorkersUsingDirectSemanticSearch({
    jobPositionId,
    maxRestaurantDistance
  }: {
    jobPositionId?: string;
    maxRestaurantDistance?: number;
  }) {
    return dynamicAxios(this.jwt).get<WorkerListItem[]>(
      `${ENDPOINTS.workers.root}/job_positions/${jobPositionId}/similar`,
      { params: { max_restaurant_distance: maxRestaurantDistance } }
    );
  }

  fetchEmployerJobApplications({
    limit,
    offset,
    employerId,
    search_vector,
    spontaneous_applications,
    organizationId
  }: {
    limit: number;
    offset: number;
    employerId?: string;
    organizationId?: string;
    search_vector?: string;
    spontaneous_applications?: boolean;
  }) {
    return dynamicAxios(this.jwt).post<EmployerJobApplications>(
      ENDPOINTS.worker_job_positions.graphql,
      {
        query: indexEmployerJobApplicationsQuery,
        variables: {
          limit,
          offset,
          employer_id: employerId,
          search_vector,
          spontaneous_applications,
          organization_id: organizationId
        }
      }
    );
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceWorker(jwt), [jwt]);
};
export default useService;

export const indexWorkersQuery = `
query index_workers (
    $limit: Int!,
    $offset: Int!,
    $order_by: [String],
    $job_position_id: String,
    $age_from: Int,
    $age_to: Int,
    $transportation_options: [String],
    $max_restaurant_distance: String,
    $experience: [String],
    $domain_interest: [String],
    $occupations: [String],
    $research_status_key: [String],
    $not_in_other_shortlists: Boolean,
    $sex: [String],
    $languages: [String],
    $skills: [String],
    $job_type: [String],
    $association_status: String,
    $job_application_status: String,
    $displacement_availability: [String],
    $search_vector: String,
    $is_contacted: Boolean,
    $granularity: String,
    $similarity_radius: Int,
    $limit_similar_job_positions: Int,
    $application_date_threshold: DateTime,
    $similar_job_positions_filter: String,
    $exclude_same_employer_similar_job_position: Boolean,
    $semantic_search: String
  ) {
    index_workers (
      limit: $limit,
      offset: $offset,
      order_by: $order_by,
      job_position_id: $job_position_id,
      age_from: $age_from,
      age_to: $age_to,
      transportation_options: $transportation_options,
      max_restaurant_distance: $max_restaurant_distance,
      experience: $experience,
      domain_interest: $domain_interest,
      occupations: $occupations,
      research_status_key: $research_status_key,
      not_in_other_shortlists: $not_in_other_shortlists,
      sex: $sex,
      languages: $languages,
      skills: $skills,
      job_type: $job_type,
      association_status: $association_status,
      job_application_status: $job_application_status,
      displacement_availability: $displacement_availability,
      search_vector: $search_vector,
      is_contacted: $is_contacted,
      granularity: $granularity,
      similarity_radius: $similarity_radius,
      limit_similar_job_positions: $limit_similar_job_positions,
      application_date_threshold: $application_date_threshold,
      similar_job_positions_filter: $similar_job_positions_filter,
      exclude_same_employer_similar_job_position: $exclude_same_employer_similar_job_position,
      semantic_search: $semantic_search
    ) {
      data {
        active,
        distance,
        job_application {
          id,
          job_position_id,
          status_key,
          is_contacted,
          is_contacted_at,
          is_contacted_by,
          is_contacted_by_rw_member {
            user_id
            name
            surname
            roles {
              id
              key
            }
          }
          source,
          ambassador_code,
          status {
            sentiment,
            label
          },
          last_bench_status_change_timestamp
          conversation_steps {
            id
            closed_at
            status
            owner {
              user_id
              roles {
                id
                key
              }
            }
            category_var {
              id
              label
              key
            }
          }
        },
        worker {
          user_id,
          user {
              last_login,
              email,
              phone_number
          },
          birthdate,
          assets {
              type,
              url
          },
          research_info {
              foreground,
              occupations {
                  label_it {
                      label
                  }
              },
              domain_interest_var {
                  label
              }
          },
          notes {
              id
          },
          worker_shortlist_notes {
              worker_id,
              shortlist_id
          },
          name,
          surname,
          cv,
          transportation_options {
              transportation_var {
                  id,
                  key,
                  label,
              }
          },
          experience_var {
              label
          },
          research_status_last_updated,
          research_status {
              key,
              id,
              label,
          },
          emails {
              primary,
              email
          },
          sex_var {
              label
          },
          locality,
          address,
          phone_numbers {
              phone_number
          },
          presentation,
          motivation_var {
              label
          },
          displacement_availability_var {
              label
          },
          job_type_var {
              label
          },
          job_types {
            job_type_var {
              label
            }
          },
          shifts {
            shift_var {
              label
            }
          },
          past_experiences {
              occupation {
                  label_it {
                      label
                  }
              },
              from_date,
              to_date,
              restaurant_name,
              restaurant_type_var {
                  label
              },
              city,
              description,
              skills {
                  id,
                  label_it {
                      label
                  }
              }
          }
        }
      },
      hits
    }
  }
`;

export const indexEmployerJobApplicationsQuery = `
  query index_employer_job_applications (
    $limit: Int!,
    $offset: Int!,
    $employer_id: String,
    $organization_id: String,
    $search_vector: String,
    $spontaneous_applications: Boolean
  ) {
    index_employer_job_applications (
      limit: $limit,
      offset: $offset,
      employer_id: $employer_id,
      search_vector: $search_vector,
      spontaneous_applications: $spontaneous_applications,
      organization_id: $organization_id
    ) {
      hits
      data {
        worker_distance
        shortlist_inserted_at
        job_application {
          id
          worker {
            user_id
            name
            surname
            birthdate
            cv
            user {
              email
            }
            phone_numbers {
              phone_number
              primary
            }
            transportation_options {
              transportation_var {
                key
                label
              }
            }
            main_picture
          }
          job_position_id
          job_position {
            id
            occupation {
              id
              label_it {
                label
              }
            }
            restaurant {
              id
              restaurant_name
              address
            }
          }
          restaurant_id
          restaurant {
            id
            restaurant_name
            address
          }
          status_key
          status {
            key
            label
            sentiment
          }
        }
        worker_shortlist_note
        shortlist_id
      }
    }
  }
`;

import Page from 'components/Page';
import * as React from 'react';
import { Container, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { useRWMemberService } from 'service';
import SidebarFiltersMainContainer from 'components/SidebarMainContainer';
import useSettings from 'hooks/useSettings';

import TeamMatesTable from './TeamMatesTable';
import TeamMateSearch from './TeamMatesSearch';
import TeamMatesSideBarFilterDrawer from './TeamMatesSideBarFilterDrawer';

interface ITeamMatesProps {}

const TeamMates: React.FunctionComponent<ITeamMatesProps> = (props) => {
  const { themeStretch } = useSettings();
  useAvailableRoles();
  return (
    <Page title="Squadra">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <SidebarFiltersMainContainer>
          <Typography variant="h3" textAlign="center">
            Il team di Restworld
          </Typography>
          <Typography variant="subtitle1" gutterBottom align="center">
            Coming together is a beginning, staying together is progress, and working together is
            success." —Henry Ford
          </Typography>
          <TeamMateSearch />
          <TeamMatesTable />
        </SidebarFiltersMainContainer>
        <TeamMatesSideBarFilterDrawer />
      </Container>
    </Page>
  );
};

export default TeamMates;
export const AVAILABLE_ROLES = 'available_roles';
const useAvailableRoles = () => {
  const rwServices = useRWMemberService();

  return useQuery({
    queryKey: [AVAILABLE_ROLES],
    queryFn: async () => (await rwServices.fetchAvailableRoles()).data,
    staleTime: Infinity
  });
};

import {
  EntityId,
  OrderBy,
  RWMemberRoleKeys,
  JobPositionTask,
  User,
  RWMemberRole
} from '@restworld/utility-types';
import { ENDPOINTS } from 'constants/api';
import useAuth from 'hooks/useAuth';
import { useMemo } from 'react';
import { Service } from 'service/baseService';
import dynamicAxios from 'utils/axios';
import { formatISO } from 'date-fns';

export type RWMember = {
  user_id: string;
  user?: User;
  name: string;
  surname: string;
  icon_url?: string;
  roles?: RWMemberRole[];
  inserted_at: string;
  updated_at: string;
  position?: string;
  email?: string;
  password?: string;
  confirm_password?: string;
  phone_number?: string;
};
export type RwMemberWorkload = {
  workload: number;
  rw_member: RWMember;
};
export type RWMemberUpdatePayloadType = Partial<Omit<RWMember, 'roles'>> & {
  roles: RWMemberRoleKeys[];
};
class ServiceRWMembers extends Service {
  fetchRWMembers({
    limit,
    offset,
    roles,
    excludingUserId,
    searchVector
  }: {
    limit?: number;
    offset?: number;
    roles?: RWMemberRoleKeys[];
    excludingUserId?: string;
    searchVector?: string;
  }) {
    const params: any = !!roles
      ? {
          role: roles
        }
      : {
          limit,
          offset
        };
    params.excluding_user_id = excludingUserId;
    params.search_vector = searchVector;
    return dynamicAxios(this.jwt).get<{ data: RWMember[]; hits: number }>(
      ENDPOINTS.rw_members.root,
      { params }
    );
  }

  createRwMember(data: RWMemberUpdatePayloadType) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.rw_members.root, data);
  }

  updateRwMember(rwMemberId: EntityId, data: RWMemberUpdatePayloadType) {
    return dynamicAxios(this.jwt).put(`${ENDPOINTS.rw_members.root}/${rwMemberId}`, data);
  }

  fetchRoles({ user_id }: { user_id: EntityId }) {
    return dynamicAxios(this.jwt).get<RWMemberRole[]>(`${ENDPOINTS.rw_members.root}/roles`, {
      params: { id: user_id }
    });
  }

  fetchAvailableRoles() {
    return dynamicAxios(this.jwt).get<RWMemberRole[]>(
      `${ENDPOINTS.rw_members.root}/available_roles`
    );
  }

  deleteRwMember(rwMemberId: EntityId) {
    return dynamicAxios(this.jwt).delete(`${ENDPOINTS.rw_members.root}/${rwMemberId}`);
  }

  getWallet(csmId?: EntityId, referenceDate?: Date) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.rw_members.wallet, {
      params: {
        csm_id: csmId,
        date: !!referenceDate ? formatISO(referenceDate, { representation: 'date' }) : undefined
      }
    });
  }

  getActiveApplications({
    csmId,
    count,
    limit,
    offset,
    category
  }: {
    csmId?: EntityId;
    count?: boolean;
    limit?: number;
    offset?: number;
    category?: 'stage' | 'interview' | 'pending';
  }) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.rw_members.active_applications, {
      params: { csm_id: csmId, count, limit, offset, category }
    });
  }

  getAgenda({
    day,
    rwMemberId,
    overdue,
    nextWeek,
    monthlyWorkload,
    orderBy
  }: {
    day?: string;
    rwMemberId?: EntityId;
    overdue?: boolean;
    nextWeek?: boolean;
    monthlyWorkload?: string;
    orderBy?: OrderBy;
  }) {
    return dynamicAxios(this.jwt).get<JobPositionTask[]>(ENDPOINTS.rw_members.agenda, {
      params: {
        rw_member_id: rwMemberId,
        day,
        overdue,
        next_week: nextWeek,
        monthly_workload: monthlyWorkload,
        order_by: !!orderBy?.variable ? `${orderBy.order}:${orderBy.variable}` : undefined
      }
    });
  }

  getRestaurantReferentAssociatedTasks({
    referentUserContactIds,
    csmId
  }: {
    referentUserContactIds: string[];
    csmId?: EntityId;
  }) {
    return dynamicAxios(this.jwt).get<JobPositionTask[]>(ENDPOINTS.rw_members.associated_tasks, {
      params: { csm_id: csmId, referent_user_contact_ids: referentUserContactIds }
    });
  }

  getRwMemberWorkload({ lowestWorkload }: { lowestWorkload?: boolean }) {
    return dynamicAxios(this.jwt).get<RwMemberWorkload[]>('/rw_member/workload', {
      params: { lowest_workload: lowestWorkload }
    });
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceRWMembers(jwt), [jwt]);
};
export default useService;

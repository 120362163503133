import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRWMemberService } from 'service';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useAtomValue } from 'jotai';
import { useQueryClient } from '@tanstack/react-query';

import { fetchRWMemberQueryKeyAtom, selectedTeamMatesAtom } from './TeamMatesTable';

export default function TeamMatesDeleteConfirmationDialog({
  open,
  setOpen
}: {
  open: boolean;
  setOpen: () => void;
}) {
  const [loading, setLoading] = React.useState(false);
  const rwServices = useRWMemberService();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const selectedTeamMates = useAtomValue(selectedTeamMatesAtom);
  const fetchRWMemberQueryKey = useAtomValue(fetchRWMemberQueryKeyAtom);

  const handleClose = React.useCallback(() => setOpen(), [setOpen]);

  const onDelete = React.useCallback(async () => {
    if (!selectedTeamMates?.user_id) return;
    try {
      setLoading(true);
      await rwServices.deleteRwMember(selectedTeamMates?.user_id);
      queryClient.invalidateQueries({
        queryKey: fetchRWMemberQueryKey
      });
      enqueueSnackbar('Team mate deleted successfully', { variant: 'success' });
      handleClose();
    } catch (error) {
      console.error(`Error deleting team mate: ${error}`);
      enqueueSnackbar('Error deleting team mate', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    fetchRWMemberQueryKey,
    handleClose,
    queryClient,
    rwServices,
    selectedTeamMates?.user_id
  ]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Conferma eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Stai eliminando un membro del team. Vuoi procedere?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Annulla
        </Button>
        <LoadingButton
          loading={loading}
          color="warning"
          onClick={onDelete}
          autoFocus
          variant="contained"
        >
          Conferma
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

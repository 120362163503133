import { EntityId, VariableId, RWMember } from '@restworld/utility-types';
import { ENDPOINTS } from 'constants/api';
import { Service } from 'service/baseService';
import dynamicAxios from 'utils/axios';
import { useMemo } from 'react';
import useAuth from 'hooks/useAuth';
import { RestaurantUpdated } from './organizations';

export interface RestaurantFilterType {
  csm?: EntityId;
  locality?: string[];
  type?: VariableId;
  open_jps?: boolean;
  my_work?: boolean;
  search_vector?: string;
  employer_id?: EntityId | null;
}

export interface IRestaurantsPayload
  extends Omit<Partial<RestaurantUpdated>, 'client_types' | 'closing_days' | 'shifts' | 'types'> {
  client_types: { client_type: string }[];
  closing_days: { closing_day: string }[];
  shifts: { shift: string }[];
  types: { type: string }[];
}

class ServiceRestaurants extends Service {
  createRestaurant(params: IRestaurantsPayload) {
    return dynamicAxios(this.jwt).post<{ id: string }>(ENDPOINTS.restaurants.root, { ...params });
  }

  fetchRestaurants({
    limit,
    offset,
    order_by,
    filters
  }: {
    limit?: number;
    offset?: number;
    order_by?: string[];
    filters?: RestaurantFilterType & { organization_id?: string };
  }) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.restaurants.root, {
      params: { limit, offset, order_by, ...filters }
    });
  }

  showRestaurant(restaurantId: string) {
    return dynamicAxios(this.jwt).get<{
      restaurant: RestaurantUpdated;
      csm: RWMember;
    }>(ENDPOINTS.restaurants.root + '/' + restaurantId);
  }

  updateRestaurant(restaurantId: string, params: IRestaurantsPayload) {
    return dynamicAxios(this.jwt).put(ENDPOINTS.restaurants.root + '/' + restaurantId, params);
  }

  uploadPicture(restaurantId: EntityId, file: File) {
    const formData = new FormData();
    formData.append('id', restaurantId);
    formData.append('picture', file);
    return dynamicAxios(this.jwt).post(ENDPOINTS.restaurants.picture, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  deletePicture(restaurantId: EntityId, pictureUrl: string) {
    return dynamicAxios(this.jwt).delete(ENDPOINTS.restaurants.picture, {
      data: { id: restaurantId, url: pictureUrl }
    });
  }

  fetchLocalities() {
    return dynamicAxios(this.jwt).get(ENDPOINTS.restaurants.localities);
  }

  associateNewReferentContact({
    restaurantId,
    employerUserContactId
  }: {
    restaurantId?: string;
    employerUserContactId?: string | string[];
  }) {
    return dynamicAxios(this.jwt).post<string>(ENDPOINTS.restaurants.referentContact, {
      restaurant_id: restaurantId,
      employer_user_contact_id: employerUserContactId
    });
  }

  removeReferentContactAssociation({
    restaurantId,
    employerUserContactId
  }: {
    restaurantId?: string;
    employerUserContactId?: string;
  }) {
    return dynamicAxios(this.jwt).delete<string>(ENDPOINTS.restaurants.referentContact, {
      data: { restaurant_id: restaurantId, employer_user_contact_id: employerUserContactId }
    });
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceRestaurants(jwt), [jwt]);
};
export default useService;

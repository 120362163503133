import * as React from 'react';

import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import { Info, Search } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';

import { useOrganizationService } from 'service';

import OrganizationQueryListUI from './OrganizationQueryListUI';
import { organizationQueryInputTextAtom, organizationQueryKeyAtom } from './RestaurantForm.atoms';

interface ISearchOrganizationProps {}

const SearchOrganization: React.FunctionComponent<ISearchOrganizationProps> = (props) => {
  const organizationService = useOrganizationService();
  const [organizationQueryInputText, setOrganizationQueryInputText] = useAtom(
    organizationQueryInputTextAtom
  );
  const organizationQueryKey = useAtomValue(organizationQueryKeyAtom);

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setOrganizationQueryInputText(e.target.value);
    },
    [setOrganizationQueryInputText]
  );

  const { isFetching } = useQuery({
    queryKey: organizationQueryKey,
    queryFn: async () => {
      return (
        await organizationService.indexOrganizations({
          limit: 20,
          offset: 0,
          filters: {
            csm: '',
            locality: [],
            ...(organizationQueryInputText ? { search_vector: organizationQueryInputText } : {})
          }
        })
      ).data.data;
    },
    placeholderData: (prevData) => prevData ?? [],
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  React.useEffect(() => {
    setOrganizationQueryInputText('');
  }, [setOrganizationQueryInputText]);

  return (
    <Stack alignItems="center">
      <Stack sx={{ width: '50%' }}>
        <Stack direction="row" alignItems="center" spacing={1} justifyContent="center">
          <Typography variant="h5">Cerca l'employer corrispondente:</Typography>
          <Tooltip title="Inserisci i dati dell'employer per il quale desideri creare un locale. Quindi, fai clic su uno dei risultati proposti e avvia la registrazione">
            <Info fontSize="medium" sx={{ color: 'grey.500' }} />
          </Tooltip>
        </Stack>
        <Box sx={{ mt: 2 }}>
          <TextField
            autoFocus
            placeholder="Cerca..."
            variant="outlined"
            fullWidth
            InputProps={{
              ...(isFetching
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress thickness={2} size={20} />
                      </InputAdornment>
                    )
                  }
                : {}),
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            value={organizationQueryInputText}
            onChange={handleInputChange}
          />
        </Box>
        <Grid container sx={{ mt: 1 }}>
          <OrganizationQueryListUI isLoading={isFetching} />
        </Grid>
      </Stack>
    </Stack>
  );
};

export default SearchOrganization;

import { Link } from 'react-router-dom';
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ArrowBackIos as ArrowBackIosIcon
} from '@mui/icons-material';
import {
  Accordion as AccordionMui,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
  Badge,
  BadgeProps,
  CircularProgress,
  Tooltip
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent
} from '@mui/lab';
import { formatTime } from 'utils/common';
import { EntityId, JobPositionTask } from '@restworld/utility-types';
import Drawer from './Drawer';
import { MAIN_PATHS } from 'routes/paths';
// import { useRwMembersService } from '@restworld/data-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useComponentStatus from 'hooks/useComponentStatus';
import { JobPositionUpdated } from './JobPositionActions';
import { useRWMemberService } from 'service';

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`
}));
const Accordion = styled(AccordionMui)({
  '&.Mui-expanded': {
    boxShadow: 'None'
  }
});

const ItemLabel = styled(Typography)({
  fontWeight: 600
});
const AccordionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600]
}));

const CenteredBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    // right: -3,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}));

type ContextType = 'associated_tasks';
const contexts: ContextType[] = ['associated_tasks'];

export type JobPositionTaskUpdated = JobPositionTask & { job_position?: JobPositionUpdated };

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  task?: JobPositionTaskUpdated;
  csmId?: EntityId;
}
export default function JobPositionTaskDetail({ isOpen, onClose, task: inputTask, csmId }: Props) {
  // const rwMemberService = useRwMembersService();
  const tempRwMemberService = useRWMemberService();
  const { status, updateStatus } = useComponentStatus(contexts);

  const [associatedTasks, setAssociatedTasks] = useState<JobPositionTaskUpdated[]>([]);
  const [task, setTask] = useState<JobPositionTaskUpdated | undefined>(inputTask);
  const [taskStack, setTaskStack] = useState<JobPositionTaskUpdated[]>([]);

  useEffect(() => {
    setTask(inputTask);
    !!inputTask && setTaskStack([inputTask]);
  }, [inputTask]);

  const referentUserContactIds = useMemo(() => {
    return task?.job_position?.restaurant?.referent_user_contacts?.map((euc) => euc.user_id);
  }, [task?.job_position?.restaurant?.referent_user_contacts]);

  const fetchAssociatedTasks = useCallback(() => {
    if (referentUserContactIds && referentUserContactIds?.length > 0) {
      const context: ContextType = 'associated_tasks';
      updateStatus(context, 'LOADING');
      tempRwMemberService
        .getRestaurantReferentAssociatedTasks({
          referentUserContactIds,
          csmId
        })
        .then((res) => {
          updateStatus(context, 'SUCCESS');
          setAssociatedTasks(res.data.filter((t) => task?.id !== t.id));
        })
        .catch((err) => {
          updateStatus(context, 'ERROR', err?.data?.error);
        });
    }
  }, [referentUserContactIds, csmId, tempRwMemberService, task?.id, updateStatus]);

  useEffect(() => fetchAssociatedTasks(), [fetchAssociatedTasks]);

  const pushTask = (task: JobPositionTask) => {
    setTask(task);
    setTaskStack((prev) => [...prev, task]);
  };

  const popTask = () => {
    if (taskStack.length < 2) return;
    setTask(taskStack[taskStack.length - 2]);
    setTaskStack((prev) => prev.slice(0, -1));
  };

  return (
    <Drawer open={isOpen || false} onClose={onClose} anchor="right">
      {!!task && (
        <>
          <BoxContainer>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={1}>
                <Tooltip title={taskStack.length <= 1 ? '' : 'Torna alla task precedente'}>
                  <span>
                    <IconButton
                      sx={{ '&:hover': { bgcolor: 'transparent' } }}
                      onClick={popTask}
                      disabled={taskStack.length <= 1}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Button
                  component={Link}
                  variant="outlined"
                  color="inherit"
                  to={`${MAIN_PATHS.jobPositions}/${task.job_position_id}`}
                >
                  Vai alla posizione
                </Button>
              </Stack>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </BoxContainer>
          <Divider />
          <BoxContainer>
            <Stack direction="row" justifyContent="flex-end" sx={{ px: 1 }}>
              <Typography sx={{ fontWeight: 'semibold' }}>Scadenza</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" sx={{ px: 1 }}>
              <Typography variant="h4">Dettagli task</Typography>
              <Typography variant="h4">
                {formatTime({ dateTime: task.due_date, noTime: true })}
              </Typography>
            </Stack>
            <Typography variant="body2" sx={{ px: 1 }}>
              Creata il: {formatTime({ dateTime: task.inserted_at, noTime: true })}
            </Typography>
            <Accordion defaultExpanded sx={{ mt: 4 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <AccordionTitle variant="subtitle2">INFORMAZIONI</AccordionTitle>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container sx={{ maxWidth: '480px' }}>
                  <Grid item xs={4}>
                    <ItemLabel>Referenti</ItemLabel>
                  </Grid>
                  <Grid item xs={8}>
                    {task?.job_position?.restaurant?.referent_user_contacts?.map((euc) => {
                      return (
                        <Typography key={euc.user_id}>
                          {euc?.user?.name} {euc?.user?.surname}
                        </Typography>
                      );
                    })}
                  </Grid>
                  <Grid item xs={4}>
                    <ItemLabel>Categoria</ItemLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>{task.category_var?.label}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <ItemLabel>Sottocategoria</ItemLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>{task.sub_category_var?.label}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <ItemLabel>Ruolo</ItemLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>{task.job_position?.occupation?.label_it.label}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <ItemLabel>Locale</ItemLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>{task.job_position?.restaurant.restaurant_name}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant="h6">Descrizione</Typography>
                    <Typography>{task.description}</Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <AccordionTitle variant="subtitle2">CONTATTI REFERENTE</AccordionTitle>
              </AccordionSummary>
              <AccordionDetails>
                {task?.job_position?.restaurant?.referent_user_contacts?.map((euc) => {
                  return (
                    <Grid container sx={{ maxWidth: '480px', pb: 2 }} key={euc.user_id}>
                      <Grid item xs={4}>
                        <ItemLabel>Email</ItemLabel>
                      </Grid>
                      <Grid item xs={8} sx={{ textTransform: 'lowercase' }}>
                        <Typography>{euc?.user?.email}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <ItemLabel>Telefono</ItemLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>{euc?.user?.phone_number}</Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            <Accordion
              disabled={
                status?.associated_tasks.status === 'LOADING' || associatedTasks.length === 0
              }
            >
              <AccordionSummary
                expandIcon={
                  status?.associated_tasks.status === 'LOADING' ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <CenteredBadge
                  invisible={status?.associated_tasks.status === 'LOADING'}
                  badgeContent={associatedTasks.length}
                  color="primary"
                >
                  <AccordionTitle variant="subtitle2" sx={{ pr: 2 }}>
                    TASK CORRELATE ALLO STESSO REFERENTE
                  </AccordionTitle>
                </CenteredBadge>
              </AccordionSummary>
              <AccordionDetails>
                <Timeline position="right">
                  {associatedTasks.map((task) => (
                    <TimelineItem key={task.id} sx={{ '&:before': { flex: 0 } }}>
                      <TimelineSeparator>
                        <TimelineDot variant="outlined" />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Stack spacing={0.5}>
                            <Typography variant="subtitle2">
                              {task.job_position?.occupation?.label_it.label}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.600' }}>
                              {task.category_var?.label} - {task.sub_category_var?.label}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.600' }}>
                              {task.job_position?.restaurant.restaurant_name}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.600' }}>
                              {formatTime({ dateTime: task.due_date, noTime: true })}
                            </Typography>
                          </Stack>
                          <Button variant="outlined" color="inherit" onClick={() => pushTask(task)}>
                            Dettagli
                          </Button>
                        </Stack>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </AccordionDetails>
            </Accordion>
          </BoxContainer>
        </>
      )}
    </Drawer>
  );
}
